import webApi from "./api";

export class UniverSalLoginService {
    async getLoginLinks(params) {
        return webApi.get("universal-login/appraisals", { params });
    }
}

const universalLoginService = new UniverSalLoginService();

export default universalLoginService;