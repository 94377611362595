<template>
  <div class="overflow-hidden">
    <header-view />
    <div class="message-container">
      <div class="success-message">
        <div class="icon-container">
          <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="4" width="60" height="60" rx="28" fill="#D1FADF"/>
            <rect x="4" y="4" width="60" height="60" rx="28" stroke="#ECFDF3" stroke-width="8"/>
            <path d="M49 32.62V34C48.9982 37.2347 47.9508 40.3821 46.014 42.9728C44.0773 45.5635 41.3549 47.4588 38.253 48.3759C35.1511 49.293 31.8359 49.1829 28.8017 48.0619C25.7675 46.9409 23.177 44.8692 21.4164 42.1556C19.6559 39.4421 18.8197 36.2321 19.0325 33.0045C19.2453 29.7769 20.4958 26.7045 22.5974 24.2457C24.699 21.7868 27.5392 20.0731 30.6943 19.3603C33.8494 18.6474 37.1504 18.9735 40.105 20.29M49 22L34 37.015L29.5 32.515" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <h1 class="header-text">Check your email</h1>
        <p class="success-text">
          If this email is registered with us, you'll receive a link to login shortly.
        </p>
        <p class="additional-text">
          <span style="font-weight: 600 !important">Didn't receive an email?</span><br />
          <span class="success-text">
            Please check your junk folder.
          </span><br />
          <a 
            :class="['resend-link', { enabled: showRetryLink }]" 
            @click="submit"
          >Resend</a>
          <span> {{!showRetryLink ? `(retry in ${countdown} seconds)` : ""}} </span>
          <span> or visit our </span>
          <a :href="myCarLinks.troubleLogging" class="help-link">help page</a>
        </p>
      </div>
    </div>
    <footer-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import HeaderView from "@/components/layout/Header";
import FooterView from "@/components/layout/Footer";
import universalLoginService from "@/services/universal-login";
export default {
  name: "Success",
  components: {
    HeaderView,
    FooterView,
  },
  data() {
    return {
      countdown: 30,
      showRetryLink: false,
    };
  },
  computed: {
    ...mapState({
      email: (state) => state.universalLogin.email,
      locid: (state) => state.universalLogin.locid,
      myCarLinks: (state) => state.commonVariables.myCarLinks,
    }),
  },
  methods: {
    startCountdown() {
      const countdownInterval = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        } else {
          this.showRetryLink = true;
          clearInterval(countdownInterval);
        }
      }, 1000);
    },
    async submit(event) {
      event.preventDefault();
      try {
        await universalLoginService.getLoginLinks({
          email: this.email,
          locid: this.locid,
        });
        this.showRetryLink = false;
        this.countdown = 30;
        this.startCountdown();
      } catch(err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.startCountdown();
  },
};
</script>

<style lang="scss">
.message-container {
  background: #f9fafb;
  padding: 16px;
}
.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: #ffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #155724;
  max-width: 358px;
  margin: 0 auto;
  text-align: center;
}
.icon-container {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e0ffe0;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.success-icon {
  width: 24px;
  height: 24px;
  color: #28a745;
}
.header-text {
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 10px;
  color: #1f8252;
}
.success-text {
  font-family: 'Avenir Next LT W05 Regular';
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 15px;
  padding: 0 20px;
  color: #667085;
}
.additional-text {
  font-family: 'Avenir Next LT W05 Regular';
  font-size: 14px;
  line-height: 20px;
  color: #6c757d;
}
.resend-link {
  color: #6c757d;
  text-decoration: underline;
  pointer-events: none;
}
.resend-link.enabled {
  color: #1f8252;
  pointer-events: auto;
  cursor: pointer;
}
.help-link {
  color: #1f8252;
  text-decoration: underline;
}
a:hover {
  color: #1f8252;
}
/* Adjust footer css */
.top-footer {
  margin-top: 30px;
}
@media screen and(min-width: 768px) {
  .success-message {
    max-width: 534px !important;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .header-text {
    font-size: 36px;
    line-height: 36px;
  }
  .icon-container {
    width: 60px;
    height: 60px;
  }
}
</style>
