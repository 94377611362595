<template>
  <div v-if="['top-page', 'thanks-page', 'universal-login', 'login-success'].includes(this.$route.name)" class="text-center">
    <div class="footer d-flex">
      <div class="top-footer d-flex pt-10 pb-8 px-4">
        <div class="footer-logo d-flex align-items-center justify-content-center my-2">
          <img
            :src="logo.logoHeaderAndFooter || '@/assets/images/logo_footer_desktop.png'"
            @click="logoFooter"
            :width="!isMobile ? '200px' : '192'"
            class="cursor-pointer"
          />
        </div>
        <div class="footer-content d-flex">
          <div class="first-content mt-2">
            <div class="logo-description">
              <p class="text-sm text-center mb-4">
                {{ footer.footerText }}
              </p>
              <a :href="`mailto:${contactDetails.email}`" class="text-sm">{{ contactDetails.email }}</a><span style="padding-left: 15px;"></span>
              <a :href="`tel:${contactDetails.phoneNumber}`" class="text-sm">{{ contactDetails.phoneNumber }}</a>
            </div>
            <div v-if="socialLinks.socialMediaIcons" class="social-media-container">
              <a :href="socialLinks.facebook" target="_blank">
                <img
                  src="@/assets/images/social_media_fb.png"
                  class="cursor-pointer"
                />
              </a>
              <a :href="socialLinks.linkedIn" target="_blank">
                <img
                  src="@/assets/images/social_media_in.png"
                  class="cursor-pointer"
                />
              </a>
              <a :href="socialLinks.instagram" target="_blank">
                <img
                  src="@/assets/images/social_media_ins.png"
                  class="cursor-pointer"
                />
              </a>
              <a :href="socialLinks.twitter" target="_blank">
                <img
                  src="@/assets/images/social_media_twitter.png"
                  class="cursor-pointer"
                />
              </a>
              <a :href="socialLinks.youtube" target="_blank">
                <img
                  src="@/assets/images/social_media_youtube.png"
                  class="cursor-pointer"
                />
              </a>
            </div>
          </div>
          <div v-if="footerBooleans.googleReviewRating" class="middle-content">
            <div class="google-review">
              <a
                :href="footer.googleReview"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img :src="footer.googleReviewBadge"
                  class="cursor-pointer mb-2"
                  style="max-width: 274px;"
                />
              </a>
            </div>
            <a :href="footer.leaveReviewLink" target="_blank" class="text-sm">Leave a review on Google</a>
          </div>
          <div class="last-content">
            <div class="links-to-website">
              <a v-if="footerBooleans.howItWorks" :href="footer.howItWorks" class="text-sm">How it works</a>
              <a v-if="footerBooleans.aboutUs" :href="footer.aboutUs" class="text-sm">About us</a>
              <a v-if="footerBooleans.forDealers" :href="footer.forDealers" class="text-sm">For dealers</a>
              <a v-if="footerBooleans.faq" :href="footer.faq" class="text-sm">FAQ</a>
              <a v-if="footerBooleans.forEnterprise" :href="footer.forEnterprise" class="text-sm">For enterprise</a>
              <a v-if="footerBooleans.contact" :href="footer.contact" class="text-sm">Contact</a>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom-footer d-flex">
        <a :href="policyDocumentsLinks.termsAndConditions" class="text-sm py-2 px-2" style="cursor: pointer;">Terms and conditions</a><span style="width: 10px;"></span>
        <a :href="policyDocumentsLinks.privacyPolicy" class="text-sm py-2 px-2" style="cursor: pointer;">Privacy Policy</a>
        <span class="px-4 py-2 text-sm">
          {{ businessNumber }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Footer",
  data() {
    return {
      isMobile: true,
    };
  },
  computed: {
    ...mapState({
      socialLinks: (state) => state.commonVariables.socialLinks,
      contactDetails: (state) => state.commonVariables.contactDetails,
      businessNumber: (state) => state.commonVariables.businessNumber,
      policyDocumentsLinks: (state) => state.commonVariables.policyDocumentsLinks,
      footerLinks: (state) => state.commonVariables.footer,
      autoflipLogo: (state) => state.commonVariables.autoflipLogo,
      footer: (state) => state.commonVariables.footer,
      logo: (state) => state.commonVariables.logo,
      footerBooleans: (state) => state.commonVariables.footerBooleans,
    }),
    mobileMediaQuery: function() {
      return window.matchMedia("(min-width: 1024px)");
    },
  },
  methods: {
    logoFooter() {
      window.location.href = this.autoflipLogo;
    },
  },
  mounted() {
    // Check mobile screen
    this.mobileMediaQuery.addListener((e) => {
      if (e.matches) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    });
    this.isMobile = !this.mobileMediaQuery.matches;
  },
};
</script>

<style lang="scss">
.text-sm {
  font-family: "Avenir Next LT W05 Regular";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #414141 !important;
}
.footer {
  flex-direction: column;
}
.top-footer {
  flex-direction: column;
}
.footer-content {
  flex-direction: column;
  align-items: center;
}
.first-content {
  .logo-description {
    max-width: 20rem;
    a {
      font-weight: 400 !important;
      line-height: 24px !important;
      text-decoration: underline;
      cursor: pointer;
      &:hover {
        text-decoration: none;
      }
    }
  }
  .social-media-container {
    margin: 2.5rem 0;
  }
}
.middle-content {
  margin-top: 0.5rem;
  a {
    font-weight: 400 !important;
    line-height: 24px !important;
    font-size: 16px !important;
    text-decoration: underline;
    color: #6F6F6F !important;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: rgb(31 130 82) !important;
    }
  }
}
.last-content {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  .links-to-website {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 358px;
    a {
      font-weight: 400 !important;
      padding: 0 10px;
      &:hover {
        color: rgb(31 130 82) !important;
      }
    }
  }
}
.bottom-footer {
  flex-wrap: wrap;
  justify-content: center;
  border-top: 2px solid #F3F4F6;
  padding: 0.5rem;
  a, span {
    font-weight: 400 !important;
    color: #6F6F6F !important;
  }
  a:hover {
    color: rgb(31 130 82) !important;
  }
}
@media screen and (min-width: 1024px) {
  .top-footer {
    width: 72rem !important;
  }
  .footer-logo {
    align-items: flex-start !important;
    justify-content: unset !important;
  }
  .footer-content {
    flex-direction: row !important;
    justify-content: space-between !important;
    align-items: unset !important;
    .first-content {
      width: 264px;
      .logo-description {
        text-align: left !important;
        margin-bottom: 2.5rem !important;
        p {
        text-align: left !important;
        }
      }
      .social-media-container {
        margin-top: unset !important;
        margin-bottom: 3rem !important;
      }
    }
    .middle-content {
      text-align: left;
      margin-top: unset !important;
      a {
        font-weight: 500 !important;
        line-height: 25px !important;
        font-size: 14px !important;
        text-decoration: underline;
        color: #6F6F6F !important;
        cursor: pointer;
        &:hover {
          text-decoration: none;
          color: rgb(31 130 82) !important;
        }
      }
    }
    .last-content {
      margin: unset !important;
      text-align: left !important;
      width: 374px;
      .links-to-website {
        a {
          font-weight: 500 !important;
          line-height: 25px !important;
          padding: unset !important;
          &:hover {
            color: rgb(31 130 82) !important;
          }
        }
      }
    }
  }
  .bottom-footer {
    padding: 1rem !important;
    a, span {
      font-weight: 500 !important;
      line-height: 25px !important;
    }
  }
}
@media screen and (min-width: 768px) {
  .top-footer {
    padding-top: 1rem;
    margin: 0 auto;
  }
}
</style>
